<template>
    <div>
        <div v-if='!page_loaded'>
            <div style="font-size: 60px; text-align: center;">
                <div>
                    Loading...
                </div>
                <div>
                    <i class="spinner fa fa-circle-o-notch fa-spin" v-if='!page_loaded'></i>
                </div>
            </div>
        </div>
        <div v-if='page_loaded'>
            <div>
                <a :href="back_link">Back</a>
            </div>
            <div>
                <form class="daterangepicker-form" @submit.prevent=''>
                    <div>
                        <div>
                            <div class="filter_div2">
                                <date-range-picker 
                                    style="z-index: 1; padding-bottom: 5%;"
                                    :autoApply="true"
                                    v-model="dateRange"
                                    opens="right"
                                    :locale-data="{ firstDay: 1, format: 'dd.mm.yyyy' }"
                                    :ranges="ranges"
                                    :maxDate="maxDate"
                                >
                                </date-range-picker>
                            </div>
                            <div class="filter_div3">
                                (UTC 0)
                            </div>
                            <div class="filter_div3">
                                <button @click="updateData" class="btn btn-primary">Apply</button>
                            </div>
                        </div> 
                    </div>
                    <div>
                        <input type="checkbox" v-model="show_zero_rows"/> Show zeros rows
                    </div>
                    <div class="div-1412">
                        <i class="spinner fa fa-circle-o-notch fa-spin" v-if='!page_loaded'></i>
                    </div>
                </form>
            </div>
            <div style="padding-bottom: 20px;">
                <table>
                    <tr>
                        <td>
                            AppName            
                        </td>
                        <td>
                            Bundle ID            
                        </td>
                        <td>
                            Advertiser          
                        </td>
                        <td>
                            Geo - Os          
                        </td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold;">
                            {{ selection_data.appname }}
                        </td>
                        <td style="font-weight: bold;">
                            {{ selection_data.bundle_id }}
                            </td>
                        <td style="font-weight: bold;">
                            {{ selection_data.advertiser }} 
                        </td>
                        <td style="font-weight: bold;">
                            {{ selection_data.geo_os }} 
                        </td>
                    </tr>
                </table>
            </div>
            <div style="height: 20px;">
                {{ ma_on_display }}
            </div>

            <div class="dash_main_table_div" style="padding-top: 60px;">
                <ResizableDataTable 
                    v-if="page_loaded" 
                    :rows_data.sync="dash_data" 
                    :fields="fields" 
                    :show_c.sync="show_col" 
                    :nonIntFields="nonIntFields" 
                    :booleanFields="boolFields" 
                    :revenueFields="revenueFields" 
                    :show_columns_name="'dash'" 
                    :delete="dd"
                    :dd_from="dd_from"
                    :dd_to="dd_to"
                    :no_actions="no_actions"
                    >
                </ResizableDataTable>
            </div>
        </div>
    </div>
</template>
<script>
import ResizableDataTable from '@/components/shared/ResizableDataTable.vue';
import DateRangePicker from 'vue2-daterange-picker';
import moment from 'moment';
import { rangeRight } from 'lodash';

export default {
    components:{ResizableDataTable, DateRangePicker},
    data() {
        let today = new Date()
        let yesterday = today.setDate(today.getDate() - 1)

        let yesterdayx = new Date();
        yesterdayx.setDate(yesterdayx.getDate() - 1);
        let todayx = new Date();

        return {
            dash_data: [],
            deleteConfirm: false,
            showDeleteModal: false,
            deleteAdvName: '',
            deleteAdvId: 0,
            dataOptions: {
                sortdatafield: "adv_company_name",
                sortorder: "asc"
            },
            form: {
                date_from: yesterday,
                date_to: yesterday
            },
            fields: [],
            page_loaded: false,
            show_col: {},
            boolFields: ['adv_var_adv'],
            nonIntFields: ['adv_company_name', 'adv_csm_name', 'affise_adv_name, mmp'],
            revenueFields: ['revenue'],
            selected_list: [],
            current_list: [],
            adv_list: [],
            csm_list: [],
            filter_value: null,
            origin_list: [],
            list_types: [
                {
                    value: 'adv_company_name',
                    text: 'Company Name'
                },
                {
                    value: 'adv_csm_name',
                    text: 'CSM Name'
                }
            ],
            dateRange: {
                startDate: yesterdayx,
                endDate: yesterdayx
            },
            ranges: {
                'Today': [todayx, todayx],
                'Yesterday': [yesterdayx, yesterdayx],
                'Last 7 days': [new Date(Date.now() - 24 * 7 * 3600 * 1000), yesterdayx],
                'Last 7 days & toady': [new Date(Date.now() - 24 * 7 * 3600 * 1000), todayx],
                'Last 30 days': [new Date(Date.now() - 24 * 30 * 3600 * 1000), yesterdayx],
                'This month': [new Date(today.getFullYear(), today.getMonth(), 1), todayx],
                'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
            },
            dateRangeDelta: {
                startDate: yesterdayx,
                endDate: yesterdayx
            },
            geoOs: [],
            geoOsSelected: 'All',
            ma_on_display: '',
            dd_from: 'ddd',
            dd_to: 'bbb',
            no_actions: true,
            selected_geo_os: '',
            selection_data: {},
            back_link: '',
            show_zero_rows: false,
            maxDate: new Date()
        }
    },
    async created() {
        this.page_loaded = false;
        // get dd_from from query string
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let [day, month, year] = urlParams.get('dd_from').split('.').map(Number);
        const dd_from = new Date(year, month - 1, day);
        [day, month, year] = urlParams.get('dd_to').split('.').map(Number);
        const dd_to = new Date(year, month - 1, day);
        this.dateRange = {
            startDate: dd_from,
            endDate: dd_to
        }    
        this.geoOsSelected = urlParams.get('geo_os_selected');

        await this.getData();
        this.fields = [
            {
                name: 'appname',
                label: 'Appname',
                sortable: true,
                toggle_column: 1,
                show_c: true,
                lock_column: true
            },
            {
                name: 'global_offer_id',
                label: 'Global Offer ID',
                sortable: true,
                toggle_column: 2,
                show_c: true,
                lock_column: true
            },
            {
                name: 'global_offer_name',
                label: 'Global Offer Name',
                sortable: true,
                toggle_column: 3,
                show_c: true,
                lock_column: true
            },
            {
                name: 'bundle_id',
                label: 'Bundle ID',
                sortable: true,
                toggle_column: 7,
                show_c: true,
                lock_column: true
            },
            {
                name: 'advertiser',
                label: 'Advertiser',
                sortable: true,
                toggle_column: 8,
                show_c: true
            },
            {
                name: 'prime_served_impressions',
                label: 'Prime Served Impressions',
                sortable: true,
                toggle_column: 9,
                show_c: true,
                num_format: true
            },
            {
                name: 'affise_impressions',
                label: 'Affise Impressions',
                sortable: true,
                toggle_column: 10,
                show_c: true,
                num_format: true
            },
            {
                name: 'prime_to_affise_served_percent_impressions',
                label: 'Prime to Affise Served % Impressions',
                sortable: true,
                toggle_column: 11,
                show_c: true,
                precentage_format: true
            },
            {
                name: 'mmp_impressions',
                label: 'MMP Impressions',
                sortable: true,
                toggle_column: 12,
                show_c: true,
                num_format: true
            },
            {
                name: 'prime_to_mmp_served_percent_impressions',
                label: 'Prime to MMP Served % Impressions',
                sortable: true,
                toggle_column: 13,
                show_c: true,
                precentage_format: true
            },
            {
                name: 'prime_served_clicks',
                label: 'Prime Served Clicks',
                sortable: true,
                toggle_column: 14,
                show_c: true,
                num_format: true
            },
            {
                name: 'affise_clicks',
                label: 'Affise Clicks',
                sortable: true,
                toggle_column: 15,
                show_c: true,
                num_format: true
            },
            {
                name: 'prime_to_affise_served_percent_clicks',
                label: 'Prime to Affise Served % Clicks',
                sortable: true,
                toggle_column: 16,
                show_c: true,
                precentage_format: true
            },
            {
                name: 'mmp_clicks',
                label: 'MMP Clicks',
                sortable: true,
                toggle_column: 17,
                show_c: true,
                num_format: true
            },
            {
                name: 'prime_to_mmp_served_percent_clicks',
                label: 'Prime to MMP Served % Clicks',
                sortable: true,
                toggle_column: 18,
                show_c: true,
                precentage_format: true
            },
            {
                name: 'advertiser_revenue',
                label: 'Advertiser Revenue',
                sortable: true,
                toggle_column: 19,
                show_c: true,
                num_format: true
            },
            {
                name: 'affise_revenue',
                label: 'Affise Revenue',
                sortable: true,
                toggle_column: 20,
                show_c: true,
                num_format: true
            },
            {
                name: 'rpm',
                label: 'RPM',
                sortable: true,
                toggle_column: 21,
                show_c: true,
                num_format: true
            },
            {
                name: 'conversions',
                label: 'Conversions',
                sortable: true,
                toggle_column: 22,
                show_c: true,
                num_format: true
            },
            {
                name: 'cr',
                label: 'CR',
                sortable: true,
                toggle_column: 23,
                show_c: true,
                precentage_format: true
            },
            {
                name: 'is_sub_data_available',
                label: 'Is Sub Data Available',
                sortable: true,
                toggle_column: 24,
                show_c: true
            },
            {
                name: 'mmp',
                label: 'MMP',
                sortable: true,
                toggle_column: 25,
                show_c: true,
               // num_format: true
            },
            {
                name: 'affise_approved_conversion',
                label: 'Affise Approved Conversion',
                sortable: true,
                toggle_column: 26,
                show_c: true,
                num_format: true
            },
            {
                name: 'affise_declined_conversion',
                label: 'Affise Declined Conversion',
                sortable: true,
                toggle_column: 27,
                show_c: true,
                num_format: true
            },
            {
                name: 'affise_total_conversion',
                label: 'Affise Total Conversion',
                sortable: true,
                toggle_column: 28,
                show_c: true,
                num_format: true
            },
            {
                name: 'appsflyer_total_conversion',
                label: 'Appsflyer Total Conversion',
                sortable: true,
                toggle_column: 29,
                show_c: true,
                num_format: true
            },
            {
                name: 'appsflyer_installs',
                label: 'Appsflyer Installs',
                sortable: true,
                toggle_column: 30,
                show_c: true,
                num_format: true
            },
            {
                name: 'appsflyer_reattributions',
                label: 'Appsflyer Reattributions',
                sortable: true,
                toggle_column: 31,
                show_c: true,
                num_format: true
            },
            {
                name: 'appsflyer_reengagements',
                label: 'Appsflyer Reengagements',
                sortable: true,
                toggle_column: 32,
                show_c: true,
                num_format: true
            },
            {
                name: 'appsflyer_cr',
                label: 'Appsflyer CR',
                sortable: true,
                toggle_column: 33,
                show_c: true,
                precentage_format: true
            }
        ];
        
        for (const field of this.fields) {
           this.show_col[field.toggle_column] = true;
        }

        this.page_loaded = true;
        this.back_link = '/admin/dash?dd_from=' + moment(this.dateRange.startDate).format('DD.MM.YYYY') + '&dd_to=' + moment(this.dateRange.endDate).format('DD.MM.YYYY') + '&geo_os_selected=' + this.geoOsSelected;
    },
    methods: {
        async getData(date_from = null, date_to = null) {
            // Get id param from query string
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);

            const r = await this.$http.post(this.resources.Dash.getDataGlobal, {
                start_date: moment(this.dateRange.startDate).format('DD.MM.YYYY'),
                end_date: moment(this.dateRange.endDate).format('DD.MM.YYYY'),
                geo_os_selected: this.geoOsSelected,
                id: urlParams.get('id'),
                appname: urlParams.get('appname'),
                bundle_id: urlParams.get('bundle_id'),
                advertiser: urlParams.get('advertiser'),
                show_zero_rows: this.show_zero_rows ? 1 : 0
            })
            const data = await r.json();
            for (const k in data.table_data) {
                let name = data.table_data[k].global_offer_name;
                if (name?.toLowerCase().includes('cta')) {
                    data.table_data[k].affise_impressions = '-';
                    data.table_data[k].mmp_impressions = '-';
                }

                if (name?.toLowerCase().includes('vta')) {
                    data.table_data[k].affise_clicks = '-';
                    data.table_data[k].mmp_clicks = '-';
                }

            }
          
            
            this.dash_data = data.table_data;
           // console.log(this.dash_data);
            this.geoOs = data.geo_os_data;
            this.selected_geo_os = data.breakdown_data.geo_os;
            this.selection_data = data.selection_data;
            this.isBusy = !this.isBusy;
        },
        async updateData() {
            this.page_loaded = false;
            await this.getData(this.dateRange.startDate, this.dateRange.endDate);
            this.page_loaded = true;            
        },
        onDatesChange(v) {
            let {
                from,
                to
            } = v
            this.form.date_from = from
            this.form.date_to = to
        },
        logEvent(x, y) {
            
        },
        geoOsChanged() {
            console.log('geoOsSelected', this.geoOsSelected);
            const selectRow = this.geoOs.find(x => x.os_geo === this.geoOsSelected);
            console.log('selectRow', selectRow);
            if (selectRow.ma !== '' && selectRow.os !== '') {
                this.ma_on_display = `MA: ${selectRow.ma}, OM: ${selectRow.om}`;
            } else {
                this.ma_on_display = ``;
            }
        },
        dd() {

        }
    },
}
</script>
<style scoped>

th,td{
    padding-right: 60px;
}

h1 {
    margin-bottom: 20px;
}
.sort-icon {
    position:absolute;
    display: inline-block;
    padding-left: 1%;
}

.clickable-th {
    cursor: pointer;
}

.daterangepicker-form {
    z-index: 1000 !important;
}

.dash_table {
    margin: 0 auto;
}

.dash_row {
    text-align: center;
}

.dash_cell {
    display: inline-block;
    padding: 15px;
    border: 1px solid lightgray;
    margin: 3px;
    border-radius: 4px;
    min-width: 280px;
}

.filter_div1 {
    display: inline-block; 
    vertical-align: top; 
    padding-top: 2px;
    padding-right: 5px;
}

.filter_div2 {
    display: inline-block; 
    vertical-align: top;
    padding-top: 2px;
}

.select_style {
    padding: 6px;
    border: 1px solid lightgray;
}

.filter_div3 {
    display: inline-block; 
    padding-left: 15px;
}

.blocks_div {
    padding-top: 20px;
    padding-bottom: 20px;
}
</style>